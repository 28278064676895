@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');
@import "~bootstrap/dist/css/bootstrap.min.css";

* {
    font-family: 'IBM Plex Sans', sans-serif;
    color: black;
}
body{
    overflow-x:hidden;
}
.btn:focus{
    box-shadow: none !important;
}
.text-italic {
    font-style: italic;
}


.text-light-weight {
    font-weight: lighter;
}

.text-size {
    font-size: 20px;
}

.text-medium-weight {
    font-weight: 600;
}

.color-gray {
    color: rgb(217, 217, 217);
}


.color-red {
    color: rgb(255, 196, 196);
}


.color-yellow {
    color: rgb(255, 255, 191);
}

.bg-gray {
    background-color: rgb(217, 217, 217);
}

.bg-red {
    background-color: rgb(255, 196, 196);
}

.bg-yellow {
    background-color: rgb(255, 255, 191);
}

.bg-light-green {
    background-color: rgb(184, 204, 126);
}

.bg-light-blue {
    background-color: rgb(181, 232, 251);
}

.color-dark-green {
    color: rgb(0, 114, 142)
}

.bg-dark-green {
    background-color: rgb(0, 114, 142);
    transition: 0.3s;
}

.bg-dark-green:hover {
    background-color: rgb(1, 130, 163)
}

.color-light-green {
    color: rgb(184, 204, 126)
}


.color-green-panda {
    color: rgb(199, 212, 184)
}

.color-light-blue {
    color: rgb(181, 232, 251)
}

.border-green {
    border-color: rgb(0, 114, 142) !important;
}

.btn-disabled:disabled {
    background-color: rgb(184, 204, 126)
}

.eagle-square-dashed {
    position: absolute;
    right: 49.7%;
    bottom: 50%;
    background-color: rgba(245, 95, 95, 0.4);
    width: 19.3%;
}

.monkey-square-dashed {
    position: absolute;
    background-color: rgba(248, 248, 159, 0.4);
    left: 50.3%;
    bottom: 50%;
    width: 19.3%;
}

.bee-square-dashed {
    position: absolute;
    background-color: rgba(157, 226, 248, 0.4);
    right: 49.7%;
    top: 50%;
    width: 19.3%;
}

.panda-square-dashed {
    position: absolute;
    background-color: rgba(199, 229, 162, 0.4);
    top: 50%;
    left: 50.3%;
    width: 19.3%;
}


.eagle-ball {
    border-radius: 60%;
    top: 25%;
    left: 5%;
}

.bee-ball {
    border-radius: 60%;
    bottom: 25%;
    left: 5%;
}

.monkey-ball {
    border-radius: 60%;
    top: 25%;
    right: 5%;
}

.panda-ball {
    bottom: 25%;
    right: 5%;
    border-radius: 60%;
}

.border-result-dashed {
    left: 27%;
    top: 24.2%;
    bottom: 24.2%;
    right: 27%;
    border-style: dashed !important;
    border-color: rgba(0, 0, 0, 0.7) !important;
}

.q-info-placeholder {
    position: absolute;
    bottom: 100%;
    top: -155%;
    left: -1%;
    right: -1%;
    display: flex;
    align-items: center;
}

.q-info-placeholder span {
    transform: rotate(-90deg);
    line-height: 15px;
    width: 64px;
    font-size: 15px;
}

.flag-parent {
    width: 30px;
    margin-right: 10px;
}

@media screen and (max-width: 850px) {
    .nav-item {
        margin-bottom: 30px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    }
    .w-75{
        width:100% !important;
    }
}

@media screen and (max-width: 450px) {
    .flag-parent {
        width: 20px;
    }

    .logo {
        max-width: 120px !important;
    }

    .btn {

    }
}

@media screen and (max-width: 550px) {
    .list-group-item{
        flex-direction: column;
    }
    .list-group-item>span{
        margin-bottom: 10px;
    }
    .q-info-placeholder{
        display: none;
    }
}

.auth-container{
    max-width: 500px;
}
